import React, { Fragment } from 'react'
//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import DashboardCard from '../components/dashboard-card/dashboard-card.component.jsx'
import '../css/dashboard.scss'

import dashboardLogo from '../images/dashboard-logo.svg'
import search from '../images/search.svg'
import cart from '../images/cart.svg'
import menu from '../images/menu-icon.svg'
import filter from '../images/filter.svg'
import newTab from '../images/new.svg'
import add from '../images/add.svg'
import remainingImg from '../images/remaining.svg'
import consumedImg from '../images/consumed.svg'
import customerEngagement from '../images/customer-engagement.svg'
import operationsExcellence from '../images/operations-excellence.svg'
import employeesProductivity from '../images/employees-productivity.svg'
import actionableInsights from '../images/actionable-insights.svg'
import { Helmet } from 'react-helmet'

export const dashboardData = [
	{
		imageUrl: customerEngagement,
		title: 'Customer Engagement',
		subtitle: 'Get Your Own Mobile App for Industry Specific',
		dtsu: '100',
		newtabStyle: {
			width: '48px',
			height: '48px',
		},
		newtabImg: newTab,
		addStyle: {
			width: '48px',
			height: '48px',
		},
		addImg: add,
	},
	{
		imageUrl: actionableInsights,
		title: 'Actionable Insighets',
		subtitle: 'Create Dashboards ',
		dtsu: '100',
		newtabStyle: {
			width: '48px',
			height: '48px',
		},
		newtabImg: newTab,
		addStyle: {
			width: '48px',
			height: '48px',
		},
		addImg: add,
	},
	{
		imageUrl: employeesProductivity,
		title: 'Employees Productivity',
		subtitle: 'KPI Builder , Work Confirmation, Create Oracle Requests',
		dtsu: '50',
		newtabStyle: {
			width: '48px',
			height: '48px',
		},
		newtabImg: newTab,
		addStyle: {
			width: '48px',
			height: '48px',
		},
		addImg: add,
	},
	{
		imageUrl: operationsExcellence,
		title: 'Operations Excellence',
		subtitle: 'Tenant/lessee Managmentc',
		dtsu: '100',
		newtabStyle: {
			width: '48px',
			height: '48px',
		},
		newtabImg: newTab,
		addStyle: {
			width: '48px',
			height: '48px',
		},
		addImg: add,
	},
]
export const userData = {
	remaining: '200',
	consumed: '350',
}

const Dashboard = () => (
	<Fragment>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
		<Layout>
			{/* <SEO title="Dashboard" /> */}
			<div className="dashboard-page">
				<header className="dashboard-header">
					<Link className="logo-parent" data-track-location="dashboard" to="/">
						<img className="logo-img" src={dashboardLogo} alt="logo" />
					</Link>
					<nav className="dashboard-nav">
						<form className="dashboard-search" action="/">
							<input
								type="text"
								className="search-input"
								placeholder="Search"
								name="search"
							/>
							<button type="submit" className="search-btn">
								<img className="search-img" src={search} alt="search" />
							</button>
						</form>
						<Link className="cart-parent" data-track-location="dashboard" to="/">
							<img className="cart-img" src={cart} alt="cart" />
						</Link>
						<div className="menu">
							<img className="menu-img" src={menu} alt="menu" />
						</div>
						<button type="button" aria-label="toggle" className="filter">
							<img className="filter-img" src={filter} alt="filter" />
						</button>
					</nav>
				</header>
				<div className="page-content">
					<h1 className="dashbord-slogan">
						<span>Welcome to</span>
						<span>iCloud Ready Platform</span>
					</h1>
					<div className="dashboard-cards">
						{dashboardData.map((data, index) => (
							<DashboardCard
								key={index}
								imageUrl={data.imageUrl}
								title={data.title}
								subtitle={data.subtitle}
								dtsu={data.dtsu}
								newtabStyle={data.newtabStyle}
								newtabImg={data.newtabImg}
								addStyle={data.addStyle}
								addImg={data.addImg}
							></DashboardCard>
						))}
					</div>
				</div>
				<footer className="dashboard-footer">
					<div className="footer-content">
						<div className="remaining">
							<img className="remaining-img" src={remainingImg} alt="remaining" />
							<span>Remaining DTSUs: </span>
							<span>{userData.remaining}</span>
						</div>
						<div className="consumed">
							<img className="consumed-img" src={consumedImg} alt="consumed" />
							<span>Consumed DTSUs: </span>
							<span>{userData.consumed}</span>
						</div>
					</div>
				</footer>
			</div>
		</Layout>
	</Fragment>
)

export default Dashboard
