import React from "react";
import "./dashboard-card.styles.scss";
import CustomButton from "../custom-button/custom-button.component";

const DashboardCard = ({ imageUrl, title, subtitle, dtsu, newtabStyle, newtabImg, addStyle, addImg }) => {
  return (
  <div className="card-container">
    <div className="image-parent">
      <img src={imageUrl} className="crad-image" alt="card image" />
    </div>
    <div className="content-parent">
      <h3 className="title">{title}</h3>
      <h6 className="subtitle">{subtitle}</h6>
      <div className="card-bottom">
          <span className="dtsu">
            <span className="dtsu-value">{dtsu}</span>
            <span className="dtsu-unit">DTSU*</span>
          </span>
          <div className="card-btns">
            <CustomButton className="newtab-btn" style={newtabStyle}>
              <img src={newtabImg} alt="newtab" />.
            </CustomButton>
            <CustomButton className="add-btn" style={addStyle}>
            <img src={addImg} alt="add" />
            </CustomButton>
          </div>
          </div>
    </div>
  </div>
)};

export default DashboardCard;
